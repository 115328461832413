import { useMutation, useQueryClient } from '@tanstack/vue-query';

import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants.ts';
import { useApi } from '@/hooks/use-api';
import ApiError from '@/models/api/api-error.ts';

export function useVerifyEmailCodeMutation() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, string>({
    mutationFn: async (code: string) => {
      const response = await api.post<void>(`/auth/verify?code=${code}`);

      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY_USER_ME] });
    },
  });
}
