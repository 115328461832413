<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { computed, DefineComponent } from 'vue';
import { useRoute } from 'vue-router';

import { Toaster } from '@/components/ui/toast';
import { useLogoutInterceptor } from '@/hooks/use-logout-interceptor.ts';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import MaintenanceLayout from '@/layouts/MaintenanceLayout.vue';

import { TTooltipProvider } from './components/ui/tooltip';

interface Layouts {
  [key: string]: DefineComponent;
}

const route = useRoute();

useLogoutInterceptor();

useHead({
  title: 'Homaio',
});

const layoutName = computed<string>(() => {
  return ((route.meta.layout as string) || 'default') + '-layout';
});

const layout = computed<DefineComponent>(() => {
  let isMaintenanceMode = false;
  try {
    isMaintenanceMode = JSON.parse(import.meta.env.VITE_MAINTENANCE_MODE ?? 'false');
  } catch (e) {
    console.error(e);
  }
  if (isMaintenanceMode) {
    return layouts['maintenance-layout'];
  }
  return layouts[layoutName.value] || DefaultLayout;
});

const layouts: Layouts = {
  'default-layout': DefaultLayout as DefineComponent,
  'empty-layout': EmptyLayout as DefineComponent,
  'maintenance-layout': MaintenanceLayout as DefineComponent,
};
</script>

<template>
  <TTooltipProvider>
    <Toaster class="pointer-events-auto" />
    <component :is="layout" />
  </TTooltipProvider>
</template>
