import { useMutation, UseMutationReturnType } from '@tanstack/vue-query';

import { useApi } from '@/hooks/use-api';
import ApiError from '@/models/api/api-error.ts';

export function useSendVerifyEmailMutation(): UseMutationReturnType<void, Error, void, unknown> {
  const api = useApi();

  return useMutation<void, Error>({
    mutationFn: async () => {
      try {
        const response = await api.post<void>('auth/verify/send', {
          name: 'test',
        });

        return response.data;
      } catch (error) {
        throw error as ApiError;
      }
    },
  });
}
