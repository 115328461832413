<script setup lang="ts">
import { TButton } from '@/components/ui/button';

const reloadPage = () => {
  window.location.reload();
};
</script>

<template>
  <div class="flex h-[100dvh] w-[100dvw] flex-col items-center justify-center gap-3">
    <h1 class="text-5xl">{{ $t('layout.maintenanceMode.title') }}</h1>
    <p class="text-lg">{{ $t('layout.maintenanceMode.description') }}</p>
    <TButton @click="reloadPage">
      {{ $t('layout.maintenanceMode.reload') }}
    </TButton>
  </div>
</template>
