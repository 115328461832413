import { useMutation } from '@tanstack/vue-query';

import { useApi } from '@/hooks/use-api';
import ApiError from '@/models/api/api-error.ts';

type ResetPasswordVariables = { email: string };

export function useResetPasswordMutation() {
  const api = useApi();

  return useMutation<void, ApiError, ResetPasswordVariables>({
    mutationFn: async ({ email }: ResetPasswordVariables) => {
      const response = await api.put<void>('/auth/password/reset', {
        email,
      });

      return response.data;
    },
  });
}
