import { useMutation, useQueryClient } from '@tanstack/vue-query';

import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants.ts';
import { useAuth } from '@/hooks/auth/use-auth.ts';
import { useApi } from '@/hooks/use-api';
import { validateApiSchema } from '@/lib/zod-helpers.ts';
import ApiError from '@/models/api/api-error.ts';
import { User, UserSchema } from '@/schemas/users/user-schema.ts';

interface RegisterVariables {
  email: string;
  password: string;
  sources: string[];
  allowNewsletter: boolean;
}

export function useRegisterMutation() {
  const api = useApi();
  const queryClient = useQueryClient();
  const { login, identifyUserOnVendorServices } = useAuth();

  return useMutation<User, ApiError, RegisterVariables>({
    mutationFn: async ({ email, password, sources, allowNewsletter }: RegisterVariables) => {
      const response = await api.post<User>('auth/register', {
        email,
        password,
        sources,
        allowNewsletter,
      });

      validateApiSchema(UserSchema, response.data);

      return response.data;
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY_USER_ME] });

      queryClient.setQueryData([API_QUERY_KEY_USER_ME], data);
      identifyUserOnVendorServices(data);

      await login();
    },
  });
}
