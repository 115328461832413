<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { z } from 'zod';

import { TButton } from '@/components/ui/button';
import { TDialogHeader, TDialogContentBodyCentered, TDialogClose } from '@/components/ui/dialog';
import { FormControl, FormItem, FormMessage, FormField } from '@/components/ui/form';
import TInput from '@/components/ui/input/TInput.vue';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { useAuthDialog } from '@/hooks/auth/use-auth-dialog.ts';
import { useResetPasswordDialog } from '@/hooks/auth/use-reset-password-dialog.ts';
import ApiError from '@/models/api/api-error.ts';
import { useResetPasswordMutation } from '@/queries/auth/use-reset-password-mutation.ts';
import { AuthDialogState } from '@/types/auth/auth-dialog-state-type.ts';
import { ResetPasswordDialogState } from '@/types/auth/reset-password-dialog.ts';

import ArrowLeftIcon from '~icons/iconoir/arrow-left';

defineProps<{
  currentEmail: string;
}>();

const emits = defineEmits<{
  (e: 'update:email', payload: string | undefined): void;
}>();

const zodSchema = z.object({
  email: z.string().email(),
});

const formSchema = toTypedSchema(zodSchema);

const { handleSubmit, isSubmitting, meta, values } = useForm({
  validationSchema: formSchema,
});

const isFormValid = computed(() => meta.value.valid);
const isButtonDisabled = computed(() => !isFormValid.value || isSubmitting.value);

const errorMessage = ref('');

const router = useRouter();

const onSubmit = handleSubmit(async (formValues) => {
  try {
    await resetPasswordMutation({ email: formValues.email });
    onResetPasswordSuccess();
  } catch (e) {
    onResetPasswordError(e as ApiError);
  }
});

const onResetPasswordSuccess = () => {
  emits('update:email', values.email);
  changeModalState(ResetPasswordDialogState.waitForEmail);
};

const onResetPasswordError = (error: ApiError) => {
  errorMessage.value = error.data.message;
};

const { mutateAsync: resetPasswordMutation, isPending: isresetPasswordPending } =
  useResetPasswordMutation();

const { openDialog } = useAuthDialog();
const { closeDialog, changeModalState } = useResetPasswordDialog();

const closeDialogAndRemoveQuery = async () => {
  await router.replace({ query: {} });
  closeDialog();
};

const goBack = () => {
  closeDialog();
  openDialog(AuthDialogState.Login);
};
</script>

<template>
  <TDialogHeader class="flex flex-row bg-white lg:bg-beige-100">
    <button
      class="mr-auto w-fit content-center text-primary-600 transition hover:text-primary-700"
      @click="goBack"
    >
      <ArrowLeftIcon />
    </button>
  </TDialogHeader>
  <TDialogClose @close="closeDialogAndRemoveQuery" />
  <TDialogContentBodyCentered class="gap-3">
    <h4 class="mb-4 font-bold">{{ $t('dialog.resetPassword.enterEmail') }}</h4>
    <form class="flex flex-col gap-4" @submit="onSubmit">
      <FormField v-slot="{ componentField }" name="email">
        <FormItem>
          <FormControl>
            <TInput type="email" placeholder="Email" v-bind="componentField" />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <p v-if="errorMessage" class="text-sm text-danger-500">{{ errorMessage }}</p>
      <TButton type="submit" :disabled="isButtonDisabled">
        <TWaveLoader v-if="isresetPasswordPending" size="sm" class="bg-white" />
        <p v-else>{{ $t('dialog.resetPassword.action') }}</p>
      </TButton>
    </form>
    <p class="mt-2 text-sm font-medium text-primary-600">
      {{ $t('dialog.resetPassword.troubleLogging') }}
      <a href="mailto:team@homaio.com" class="underline underline-offset-4">{{
        $t('dialog.resetPassword.contactSupport')
      }}</a>
    </p>
  </TDialogContentBodyCentered>
</template>
