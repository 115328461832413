<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import BankAccountCard from '@/components/bank-accounts/BankAccountCard.vue';
import { TCheckbox } from '@/components/ui/checkbox';
import { TDialogHeader, TDialogClose, TDialogContentBodyCentered } from '@/components/ui/dialog';
import { TIcon } from '@/components/ui/icon';
import { TPopover, TPopoverContent, TPopoverTrigger } from '@/components/ui/popover';
import { useDepositDialog } from '@/hooks/invest/use-deposit-dialog';
import { useCreateDeposit } from '@/queries/users/use-create-user-deposit.ts';
import { useUserMeQuery } from '@/queries/users/use-users-me-query';

const { closeDialog } = useDepositDialog();
const isChecked = ref(false);

const { data: user } = useUserMeQuery();

const copyToClipboard = (value: string) => {
  return navigator.clipboard.writeText(value);
};

const homaioBankInformation = computed(() => {
  const homaioInfo = {
    bankName: 'Memo Bank SA',
    accountName: 'Homaio Carbon Holdings',
    bic: 'MEMOFRP2XXX',
    iban: 'FR76 1733 8000 0175 8883 1641 923',
    internalReference: '',
  };

  if (!user.value?.bankAccounts?.length) return homaioInfo;

  return {
    ...homaioInfo,
    internalReference: user.value.bankAccounts[0].internalReference,
  };
});

const { mutateAsync: createDepositMutation } = useCreateDeposit();

const createDeposit = async () => {
  if (!isChecked.value || !user.value || !user.value?.bankAccounts?.at(0)) return;

  try {
    await createDepositMutation({
      bankAccountId: user.value.bankAccounts[0].id,
      userId: user.value.id,
    });
  } catch (e) {
    console.error(e);
  }
};

watch(isChecked, async () => {
  if (isChecked.value) {
    await createDeposit();
  }
});
</script>

<template>
  <TDialogHeader class="flex flex-row justify-end bg-white lg:bg-beige-100" />
  <TDialogClose />
  <TDialogContentBodyCentered class="gap-10">
    <h4 class="font-bold">{{ $t('dialog.deposit.informations.title') }}</h4>
    <div class="flex flex-col gap-10 text-sm font-medium text-primary-700">
      <div class="flex flex-col gap-4">
        <p>{{ $t('dialog.deposit.informations.fromThisAccountDisclaimer') }}</p>
        <BankAccountCard can-edit @edit="closeDialog">
          <template #title>
            <span class="text-primary-600">{{ $t('component.deposit.from') }}</span>
          </template>
        </BankAccountCard>
        <div class="flex items-center justify-center gap-2">
          <TCheckbox v-model:checked="isChecked" class="border-primary-600" />
          <span>{{ $t('dialog.deposit.informations.iUnderstand') }}</span>
        </div>
      </div>
      <div>{{ $t('dialog.deposit.informations.useBelow') }}</div>
      <div class="relative flex flex-col gap-6 rounded-lg bg-white p-6">
        <div
          v-if="!isChecked"
          class="absolute left-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center gap-3 rounded-lg"
          style="backdrop-filter: blur(6px)"
        ></div>
        <div
          v-for="(value, key) in homaioBankInformation"
          :key="key"
          class="flex items-center justify-between"
        >
          <div class="flex flex-col gap-2">
            <div class="font-medium text-primary-500">
              {{ $t(`dialog.deposit.bankInformation.${key}`) }}
            </div>
            <div class="select-text font-semibold text-primary-700">{{ value }}</div>
          </div>
          <TPopover>
            <TPopoverTrigger as-child>
              <TIcon
                icon="copy"
                color="primary-600"
                size="md"
                class="cursor-pointer"
                @click="() => copyToClipboard(value)"
              />
            </TPopoverTrigger>
            <TPopoverContent
              class="w-full rounded-md bg-primary-900 px-3 py-1.5 text-sm text-invert-1000 shadow-md"
            >
              {{ $t('common.copied') }}
            </TPopoverContent>
          </TPopover>
        </div>
        <div
          class="mb-6 flex items-start gap-4 rounded-lg border border-warning-200 bg-warning-100 p-4"
        >
          <TIcon icon="warningTriangleOutline" color="warning-600" size="md" />
          <div class="flex flex-1 flex-col gap-3 font-medium text-warning-600">
            <p class="text-sm font-semibold">
              {{ $t('dialog.deposit.informations.pleaseIncludeReference') }}
            </p>
          </div>
        </div>
      </div>
      <div class="mb-6 flex items-start gap-4 rounded-lg border border-info-200 bg-info-100 p-4">
        <TIcon icon="clock" color="primary-600" size="md" />
        <div class="flex flex-1 flex-col gap-3 font-medium text-info-600">
          <p class="text-sm font-semibold">
            {{ $t('dialog.deposit.informations.processingTime') }}
          </p>
          <div class="text-sm opacity-60">
            <p>{{ $t('dialog.deposit.informations.processingTimeDescription') }}</p>
          </div>
        </div>
      </div>
    </div>
  </TDialogContentBodyCentered>
</template>
